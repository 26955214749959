section{
    background-color: #E5F2E5;
    

}
.containerProdutos{
    display: flex;    
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
    padding: 15px 0 60px;
}

.alturaPadrao{
    display: flex;
    min-height: 450px;
    justify-content: center;
}

.distancia{
    max-width: 234px;
}

.tamanhoPadrao{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    max-width: 200px;
}

.cartaoProdutos{
    background-color: white;
    padding: 30px;
    display: flex;
    border-radius: 8px;
    transition: transform 0.5s cubic-bezier(0.25, 0.1, 0.25, 1), box-shadow 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.cartaoProdutos:hover{
    cursor:pointer;
    box-shadow:0px 0px 20px 0px rgba(0, 53, 0, 0.4);
    transition: 0.3s;
    transform: scale(1.05);
}

.cartaoProdutos:active {
    transform: scale(0.95);
    box-shadow: 0px 0px 10px 0px rgba(0, 53, 0, 0.2); 
}

.descricaoProdutos{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
}

.linha{
    font-family: "Bebas Neue", sans-serif;
    font-size: 16px;
    font-weight: normal;
    background-color: #53A677;
    border-radius: 50px;
    color: white;
    padding: 5px 0;
    min-width: 110px;
    min-height: 25px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.nomeProdutos{
    font-family: "DM sans", sans-serif;
    font-size: 14px;
    min-height: 40px;    
    min-width: 200px;
}

.imagemProdutos{
    width: 200px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.patenteContainer{
    display: flex;
    justify-content: center;
}

.patente{
    width: 80px;
}