  .icon svg {
    transition: transform 0.5s ease-in-out;
  }


  .icon:hover svg {
    transform: rotate(360deg) scale(1.08);
  }

  .aviso{
    right: 70px;
    position: absolute;
    width: fit-content;
  }

  .icon-aviso{
    border: none;
  }
  
  /* tooltip-avisostyling */
  .tooltip-aviso{
    visibility: hidden;
    width: 180px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    font-size: 10px;
    padding: 6px 5px;
    position: absolute;
    bottom: 120%; /* Position above the icon */
    left: 48%;
    margin-left: -100px; /* Center the tooltip-aviso*/
    opacity: 0;
    transition:
      opacity 0.5s,
      transform 0.5s;
    transform: translateY(10px);
  }
  
  /* tooltip-avisoArrow */
  .tooltip-aviso::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 52%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }
  
  /* Show tooltip-avisoon hover */
  .tooltip-container-aviso:hover .tooltip-aviso{
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
  
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  
  .tooltip-container-aviso:hover .tooltip-aviso{
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    animation: bounce 0.6s ease;
  }
  