.conteudo{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40px;
  }
  
  .container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .background{
    margin: 10px;
    background-color: white;
    padding:20px;
    transition: 0.3s;
    min-height: 300px;
  }
  
  .background:hover{
    cursor:default;
    box-shadow:0px 0px 20px 0px rgba(0, 53, 0, 0.4);
    transition: 0.2s;
    transform: scale(1.01);
  }
  
  .info{
    width: fit-content;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 300px;
  }
  
  .nome-linha{
    margin: 0px;
    font-size: 1.125em;
    color:rgba(25, 27, 35, 1);
    font-weight: 700;
  }
  
  .produtos{
    width: 250px;
    background-color:white;
    cursor: pointer;
    
  }
  
  .botoes{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  
  .ver-produtos{
    color:#53A677;
    padding: 10px;
    font-size: 0.75em;
    border-radius: 20px;
    border: 1px solid rgb(84, 166, 119);
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
  }
  .ver-produtos:hover {
    transform: scale(1.1);
  }
  
  .baixar-catalogo{
    background-color:#53A677;
    font-weight: bold;
    color:white ;
    padding: 10px;
    font-size: 0.75em;
    border-radius: 20px;
    text-decoration: none;
    cursor: pointer;
    
  }
  .baixar-catalogo:hover {
    transform: scale(1.1);
  }
