.container-preso{
    position: sticky;
    top: 10px;
    display: flex;
    flex-direction: row-reverse;
    margin: 10px 10px 0 0;
  }

  .balao{
    display: flex;
    flex-direction: row-reverse;
    text-decoration: none;
  }
  
  .imagem-moça{
    width: 80px;
    filter: drop-shadow(0px 0px 12px grey)
  }
  
  .imagem-presa{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .botao{
    background-color:#53A677;
    padding:5px 10px;
    height: 20px;
    margin: 40px 0 0 ;
    border-radius: 35px;
    display: flex;
    align-items: center;
    filter: drop-shadow(0px 0px 12px grey);
    color:white;
  }
  
  .frase-balao{
    font-size: 0.75em;
    font-weight: 700;
  }

  